import React from "react";

export default function Navbar() {
  return (
    <div>
      <div className="homepage">
        <div className="container-fluid">
          <div className="row">
            <div className="col-7">
              <img
                src="./images/arvindforesttrails_logo.jpg"
                className="img-fluid header-logo"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
