import React from "react";

export default function Location() {
  return (
    <div>
      <div className="location">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Location</h1>
              <img src="./images/location.jpg" alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
