import React from "react";

export default function Contact() {
  return (
    <div>
      <div className="contact" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Contact Us</h1>
            </div>
            <div class="modal-body">
              <form
                name="contact"
                method="post"
                data-netlify="true"
                onSubmit="submit"
                data-netlify-honeypot="bot-field"
              >
                <input type="hidden" name="form-name" value="contact" />
                <div class="row">
                  <div class="col-12 col-lg-6 mt-3">
                    <input
                      required
                      type="text"
                      class="form-control"
                      placeholder="Name"
                      name="name"
                    />
                  </div>
                  <div class="col-12 col-lg-6 mt-3">
                    <input
                      required
                      type="text"
                      class="form-control"
                      placeholder="Phone Number"
                      name="phonenumber"
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div class="col-12 col-lg-6 mt-3">
                    <input
                      required
                      type="text"
                      class="form-control"
                      placeholder="Email"
                      name="email"
                    />
                  </div>
                  <div class="col-12 col-lg-6 mt-3">
                    <fieldset>
                      <div class="some-class">
                        <label>Need Brochure</label>{" "}
                        <br class="mobile-break"></br>
                        <input
                          type="radio"
                          class=" ml-0 ml-md-3 mr-1"
                          name="brochure"
                          value="Yes"
                        />
                        <label for="y">Yes</label>
                        <input
                          type="radio"
                          class="radio ml-3 mr-1"
                          name="brochure"
                          value="No"
                        />
                        <label for="no">No</label>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div className="row mt-3">
                  <div class="col-12 col-lg-6">
                    <input
                      type="text"
                      required
                      class="form-control"
                      placeholder="Message"
                      name="message"
                    />
                  </div>
                </div>
                {/* 
                        <div className="row">
                          <div className="col-12">
                            <div
                              style={{ fontSize: 12 }}
                              className="text-center"
                            >
                              *Phase - 1 Sold Out,{" "}
                              <b>Hurry up for Phase - 2 (NEW LAUNCH)</b>
                            </div>
                          </div>
                        </div> */}

                <div className="row">
                  <div className="col-12 text-center">
                    <button type="submit" className="btn btn-primary mt-4">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
